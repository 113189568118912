import Cookies from "universal-cookie";

export const services = {
  // backendUrl: process.env.REACT_APP_BACKEND_URL,
  frontendUrl: process.env.REACT_APP_FRONTEND_URL,
};

export const appSettings = {
  ignoreSalePermissions: JSON.parse(
    process.env.REACT_APP_IGNORE_SALE_PERMISSIONS
  ),
  isBatchStandard: JSON.parse(process.env.REACT_APP_BATCH_STANDARD),
  adminOnly: JSON.parse(process.env.REACT_APP_ADMIN_ONLY_AUTH),
  requireSalesRep: JSON.parse(process.env.REACT_APP_SALE_REP),
  isGigcOrLocal: [
    "https://gigc.invexonline.com",
    "http://localhost:3000",
  ].includes(services.frontendUrl),
  isGigc: "https://gigc.invexonline.com" === services.frontendUrl,
  isBarrelsAndCrates:
    "https://barrels-crates.invexonline.com" === services.frontendUrl,
  printerType: process.env?.REACT_APP_PRINTER
    ? process.env?.REACT_APP_PRINTER
    : "A4 Size Printer",
  isThermalPrinter: process.env?.REACT_APP_PRINTER === "Thermal Printer",
  hasWarehouse: process.env?.REACT_APP_HAS_WAREHOUSE
    ? JSON.parse(process.env?.REACT_APP_HAS_WAREHOUSE)
    : false,
  hasSize: process.env?.REACT_APP_HAS_SIZE
    ? JSON.parse(process.env?.REACT_APP_HAS_SIZE)
    : false,
  hasIncentive: process.env?.REACT_APP_HAS_INSENTIVE
    ? JSON.parse(process.env?.REACT_APP_HAS_INSENTIVE)
    : false,
  isMertesacker: "https://mertesacker.invexonline.com" === services.frontendUrl,
};

// Gigc / Local - the firs
/* export const backendApis = [
  {
    url: process.env.REACT_APP_CEMENT_BACKEND_URL,
    name: "Dangote Cement",
    port: "6001",
  },
  {
    url: process.env.REACT_APP_CEMENT_BUA_BACKEND_URL,
    name: "Bua Cement",
    port: "6022",
  },
  {
    url: process.env.REACT_APP_IRON_RODS_BACKEND_URL,
    name: "Iron Rods",
    port: "6002",
  },
  {
    url: process.env.REACT_APP_IRON_RODS_AKURE_BACKEND_URL,
    name: "Iron Rods - Akure",
    port: "7000",
  },
]; */

/* export const backendApis = [
  {
    url: "https://neu-stream-api.invexonline.com",
    name: "NEU STREAM",
    port: "6025",
  },
]; */

// Test
/* export const backendApis = [
  {
    url: "https://invex01dev.invexonline.com",
    name: "Showroom",
    port: "6023",
  },
  {
    url: "https://gigc-test-api.invexonline.com",
    name: "Warehouse",
    port: "6006",
  },
  {
    url: process.env.REACT_APP_CEMENT_BACKEND_URL,
    name: "Dangote Cement",
    port: "6001",
  },
]; */

/* {
    url: "https://invex01invex.invexonline.com",
    name: "Live Test",
    port: "6024",
  }, 
];*/

/*  {
    url: process.env.REACT_APP_TRANSPORT_BACKEND_URL,
    name: "Transport and Logistics",
  },
  {
    url: process.env.REACT_APP_OIL_BACKEND_URL,
    name: "Oil and Gas",
  },
  {
    url: "",
    name: "CrystalWire Industries",
  },
  {
    url: "",
    name: "GIGC Holdings",
  },*/

// hello energy
/* export const backendApis = [
  {
    url: "https://invex01creeklpg.invexonline.com",
    name: "Creek LPG Accessory",
    port: "6011",
  },
  {
    url: "https://invex01creeklubricant.invexonline.com",
    name: "Creek Lubricant",
    port: "6012",
  },
  {
    url: "https://invex01omagbalpg.invexonline.com",
    name: "Omagba LPG Accessory",
    port: "6013",
  },
  {
    url: "https://invex01omagbalubricant.invexonline.com",
    // name: "Omagba Lubricant",
    name: "Hello Energy Mart",
    port: "6014",
  },
  {
    url: "https://invex01nkwellelpg.invexonline.com",
    name: "Nkwelle LPG Accessory",
    port: "6015",
  },
  {
    url: "https://invex01nkwellelubricant.invexonline.com",
    name: "Nkwelle Lubricant",
    port: "6016",
  },
  {
    url: "https://invex01nkwelleprovision.invexonline.com",
    name: "Nkwelle Provision",
    port: "6017",
  },
  {
    url: "https://invex01lpgstore.invexonline.com",
    name: "LPG Warehouse",
    port: "6018",
  },
  {
    url: "https://invex01lubricantstore.invexonline.com",
    name: "Lubricant Warehouse",
    port: "6019",
  },
]; */

// mertersacker
/* export const backendApis = [
  {
    url: "https://invex01mertesackerph.invexonline.com",
    name: "MERTESACKER-PH",
    port: "6020",
  },
  {
    url: "https://invex01mertesackerbayelsa.invexonline.com",
    name: "MERTESACKER-BAYELSA",
    port: "6021",
  },
]; */

// White Gold
/* export const backendApis = [
  {
    url: "https://adminwhitegold.invexonline.com",
    name: "WHITEGOLD",
    port: "6026",
  },
] */

// Brave
/* export const backendApis = [
  {
    url: "https://adminbraveairforce.invexonline.com",
    name: "Airforce",
    port: "6027",
  },
  {
    url: "https://adminbravenkpolu.invexonline.com",
    name: "Nkpolu",
    port: "6028",
  },
  {
    url: "https://adminbraveoyigbo.invexonline.com",
    name: "Oyigbo",
    port: "6029",
  },
  {
    url: "https://adminbravetransamadi.invexonline.com",
    name: "Trans-Amadi",
    port: "6030",
  },
  {
    url: "https://adminbravewarehouse.invexonline.com",
    name: "Central Warehouse",
    port: "6031",
  },
  {
    url: "https://adminbrave242warehouse.invexonline.com",
    name: "242_Warehouse",
    port: "6032",
  },
]; */

// Stuch
/* export const backendApis = [
  {
    url: "https://adminstuch.invexonline.com",
    name: "STUCH",
    port: "6033",
  },
]; */

// white gold
/* export const backendApis = [
  {
    url: "https://adminwhitegold.invexonline.com",
    name: "WHITEGOLD",
    port: "6034",
  },
]; */

// Gnash
/* export const backendApis = [
  {
    url: "https://admingnash.invexonline.com",
    name: "GNASH",
    port: "6035",
  },
]; */

//Halfort
/* export const backendApis = [
  {
    url: "https://halfortheadquaters.invexonline.com",
    name: "HEADQUATERS",
    port: "6036",
  },
  {
    url: "https://halfortmushin.invexonline.com",
    name: "MUSHIN",
    port: "6037",
  },
  {
    url: "https://halfortketu.invexonline.com",
    name: "KETU",
    port: "6038",
  },
  {
    url: "https://halfortikotun.invexonline.com",
    name: "IKOTUN",
    port: "6039",
  },
  {
    url: "https://halfortboundary.invexonline.com",
    name: "BOUNDARY",
    port: "6040",
  },
  {
    url: "https://halfortmowe.invexonline.com",
    name: "MOWE",
    port: "6041",
  },
]; */

/* export const backendApis = [
  {
    url: "https://barrels-crates-api.invexonline.com",
    name: "SALES OUTLET",
    port: "6042",
  },
  {
    url: "https://barrels-crates-warehouse-api.invexonline.com",
    name: "WAREHOUSE",
    port: "6045",
  },
]; */

//--  test-hr-api - 6043
/* export const backendApis = [
  {
    url: "https://dewlite-api.invexonline.com",
    name: "DEWLITE",
    port: "6044",
  },
]; */

export const backendApis = [
  {
    url: "https://tonyglobal-api.invexonline.com",
    name: "Tony global ind Ltd",
    port: "6046",
  },
];
//--  mertesacker-hr-api - 6048
//--  gigc-hr-api - 6049

export const reportsNavItems = [
  /* {
    name: "Cash book",
    to: "cash-book",
  }, */
  {
    name: "Sales Analysis",
    to: "sales-analysis",
  },

  appSettings.hasIncentive
    ? {
        name: "MD's Report",
        to: "sales-incentive",
      }
    : {},

  appSettings.requireSalesRep
    ? {
        name: "Sales Rep Reports",
        to: "sales-rep-reports",
      }
    : {},
  {
    name: "Sale by Invoice",
    to: "sale-by-invoice",
  },
  {
    name: "Cash Book",
    to: "payment-report",
  },
  {
    name: "Cash/Bank Balances",
    to: "cash-bank-balances",
  },
  /* {
    name: "Transaction Analysis",
    to: "transaction-analysis",
  },
  {
    name: "Customer Transactions Analysis",
    to: "customer-transactions-analysis",
  }, */
  {
    name: "Customer Ledger",
    to: "customer-ledger",
  },
  /*  {
    name: "Loyalty Customers",
    to: "loyalty-customers",
  }, */
  {
    name: "Accounts Recievables",
    to: "accounts-recievables",
  },
  {
    name: "Accounts Payables",
    to: "accounts-payables",
  },

  {
    name: "Purchase History",
    to: "purchase-history",
  },
  /*
  {
    name: "Stock Position by Date",
    to: "stock-position-by-date",
  }, */

  {
    name: "Inventory Analysis",
    to: "inventory-analysis",
  },

  /* {
    name: "Inventory Analysis(Warehouse)",
    to: "inventory-analysis-warehouse",
  },

  {
    name: "Print Inventory By Product ",
    to: "print-inventory-by-product",
  },

  {
    name: "Vendors Payment History",
    to: "vendors-payment-history",
  },

  {
    name: "Vendors Ledger",
    to: "vendors-ledger",
  }, */

  {
    name: "Expenses",
    to: "expenses",
  },
  {
    name: "Imprest Account",
    to: "imprest-account",
  },

  {
    name: "Stock Monitor",
    to: "stock-records",
  },

  {
    name: "Financials",
    grandchildRoutes: [
      {
        name: "General Ledger",
        to: "reports/general-ledger",
      },
      {
        name: "Trial Balance",
        to: "reports/trial-balance",
      },
      {
        name: "Income Statement",
        to: "reports/income-statement",
      },
      {
        name: "Balance Sheet",
        to: "reports/balance-sheet",
      },
    ],
  },

  /*

  {
    name: "Bank Account Analysis",
    to: "bank-account-analysis",
  },


 */
];

export const salesUserReportNavItems = [
  {
    name: "Sales Analysis",
    to: "sales-analysis",
  },
  {
    name: "Sale by Invoice",
    to: "sale-by-invoice",
  },
  {
    name: "Cash Book",
    to: "payment-report",
  },
  {
    name: "Accounts Recievables",
    to: "accounts-recievables",
  },
  {
    name: "Customer Ledger",
    to: "customer-ledger",
  },

  {
    name: "Purchase History",
    to: "purchase-history",
    isCementNav: true,
  },
  {
    name: "Stock Monitor",
    to: "stock-records",
    isCementNav: true,
  },
];

export const storeUserReportNavItems = [
  {
    name: "Purchase History",
    to: "purchase-history",
  },
  {
    name: "Inventory Analysis",
    to: "inventory-analysis",
  },
  {
    name: "Stock Monitor",
    to: "stock-records",
  },
  {
    name: "Sales Analysis",
    to: "sales-analysis",
  },
];

export const durationOptions = [
  {
    value: "Day",
    label: "Day",
  },
  {
    value: "Week",
    label: "Week",
  },
  {
    value: "Month",
    label: "Month",
  },
];

export const supplyOptions = [
  { value: "Not Supplied", label: "Not Supplied" },
  {
    value: "Partial Supply",
    label: "Partial Supply",
  },
  {
    value: "Supplied",
    label: "Supplied",
  },
  { value: "Pending Delivery", label: "Pending Delivery" },
  {
    value: "Loaded & On Transit",
    label: "Loaded & On Transit",
  },
  {
    value: "Delivered & Closed",
    label: "Delivered & Closed",
  },
];

export const supplyOptionsParams = [
  {
    value: "Not Supplied & Partial Supply",
    label: "Not Supplied & Partial Supply",
  },
  { value: "Not Supplied", label: "Not Supplied" },
  {
    value: "Partial Supply",
    label: "Partial Supply",
  },
  {
    value: "Supplied",
    label: "Supplied",
  },
  { value: "Pending Delivery", label: "Pending Delivery" },
  {
    value: "Loaded & On Transit",
    label: "Loaded & On Transit",
  },
  {
    value: "Delivered & Closed",
    label: "Delivered & Closed",
  },
];

export const wareHouseChildren = [
  {
    name: "Warehouse",
    to: "warehouse",
  },
  {
    name: "Storage Location",
    to: "storage-location",
  },
];

export const vendorManagement = [
  {
    name: "Vendors",
    to: "vendor/vendors",
  },
  {
    name: "Vendors ledger",
    to: "vendor/vendors-ledger",
  },

  {
    name: "Post Vendors Payment",
    to: "vendor/post-vendors-payment",
  },
  {
    name: "Vendors Credit/Debit Memo",
    to: "vendor/creadit-debit-memo",
  },
  {
    name: "Create Memo",
    to: "vendor/create-vendors-memo",
  },
  {
    name: "Upcoming Payments",
    to: "vendor/upcoming-payments",
    isCementNav: true,
  },
  {
    name: "ATC Payment Report",
    to: "vendor/atc-payment-report",
    isCementNav: true,
  },
];

export const HRBackEndUrl = process.env.REACT_APP_HR_BACKEND;
export const HRFrontEndUrl = process.env.REACT_APP_HR_FRONTEND;

export const IS_HR = process.env.REACT_APP_IS_HR
  ? JSON.parse(process.env.REACT_APP_IS_HR)
  : false;

export const setCookies = (responseJson) => {
  let domain = process.env.REACT_APP_FRONTEND_URL.includes("localhost")
    ? null
    : ".invexonline.com";
  // console.log(responseJson);
  if (responseJson.user) {
    const expiryDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
    const cookies = new Cookies();
    cookies.set("isLoggedIn", true, {
      path: "/",
      expires: expiryDate,
      ...(domain ? { domain } : {}),
    });
    cookies.set("userId", responseJson.user._id, {
      path: "/",
      expires: expiryDate,
      ...(domain ? { domain } : {}),
    });
    cookies.set("userId", responseJson.user._id, {
      path: "/",
      expires: expiryDate,
      ...(domain ? { domain } : {}),
    });
    cookies.set("token", responseJson.user.token, {
      path: "/",
      expires: expiryDate,
      ...(domain ? { domain } : {}),
    });
    cookies.set("isFirstTime", responseJson.user.isFirstTime, {
      path: "/",
      expires: expiryDate,
      ...(domain ? { domain } : {}),
    });
    cookies.set("branch_id", responseJson.branch, {
      path: "/",
      expires: expiryDate,
      ...(domain ? { domain } : {}),
    });
    cookies.set(
      "company_id",
      responseJson.user.company_id
        ? responseJson.user.company_id
        : process.env?.REACT_APP_HR_COMPANY,
      {
        path: "/",
        expires: expiryDate,
        ...(domain ? { domain } : {}),
      }
    );

    if (responseJson.user.isAdmin || responseJson.user.isSuperAdmin) {
      if (responseJson.user.isSuperAdmin) {
        cookies.set("isSuperAdmin", true, {
          path: "/",
          expires: expiryDate,
          ...(domain ? { domain } : {}),
        });
      } else if (responseJson.user.isAdmin) {
        cookies.set("isAdmin", true, {
          path: "/",
          expires: expiryDate,
          ...(domain ? { domain } : {}),
        });
      }
    }
  }
};
